<template>
  <div class="space-y-4">
    <article class="pb-4">
      <h3 class="subheader">Quick Links</h3>
      <br />
      <section
        class="relative z-0 rounded-md grid sm:grid-cols-1 md:grid-cols-4"
      >
        <asom-button
          v-if="canCreateStationOccurrence"
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Create Station Occurrence',
            })
          "
          icon="file-document"
          text="Create Station Occurrence"
        >
        </asom-button>
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Configurations Page',
            })
          "
          icon="globe-alt"
          text="Configurations"
        >
        </asom-button>
      </section>
    </article>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card title="Station Occurrence">
      <div class="grid md:grid-cols-6 lg:gap-x-8 md:gap-x-6 gap-4">
        <asom-form-field class="col-span-3" label="Line" required>
          <asom-input-select
            :disabled="!canSelectLine"
            v-model="filters.line"
            :options="lineOptions"
          />
        </asom-form-field>
        <asom-form-field class="col-span-3" label="Station" required>
          <asom-input-select
            v-model="filters.station"
            :options="stationOptions"
          />
        </asom-form-field>
        <asom-form-field class="col-span-3" label="Date Created" required>
          <asom-input-date-range
            v-model="filters.dateRange"
            datepicker-placement="bottom"
          />
        </asom-form-field>
        <asom-form-field :label="'Item Status'" class="col-span-3">
          <asom-multi-select
            :objectModelValue="false"
            v-model="filters.isItemOpened"
            :options="itemStatusOptions"
          />
        </asom-form-field>
      </div>
      <template #footer>
        <asom-export-to-excel
          fileName="Station Occurrences"
          :fields="columns.fields"
          :labels="columns.labels"
          :data="columns.data"
        />
        <asom-button
          text="Reset Filters"
          variant="secondary"
          @click="resetFilters"
        />
        <asom-button text="Apply" @click="getPageData(true)" />
      </template>
    </asom-card>
    <div class="pt-8">
      <asom-card title="Station Occurrences">
        <asom-client-table
          v-if="!isLoading"
          :columns="[
            'occurrenceNo',
            'description',
            'occurrenceDate',
            'occurrenceTime',
            'dateCreated',
            'timeCreated',
            'expiryDate',
            'isItemOpened',
            'createdByName',
            'remarks'
          ]"
          :sortableColumns="[
            'occurrenceNo',
            'description',
            'occurrenceDate',
            'dateCreated',
            'timeCreated',
            'expiryDate',
            'isItemOpened',
            'createdByName',
          ]"
          :data="tableData"
          :searchableDateColumns="['dateCreated', 'occurrenceDate', 'expiryDate']"
          :searchableTimeColumns="['dateCreated', 'occurrenceDate', 'expiryDate']"
        >
          <template v-slot:header_isItemOpened>Item Status</template>
          <template v-slot:header_createdByName>Created By</template>
          <template v-slot:occurrenceNo="scopedSlots">
            <button
              v-if="canViewStationOccurrence"
              @click="navigateToView(scopedSlots.rowData)"
              class="text-button underline font-semibold"
            >
              {{ scopedSlots.data }}
            </button>
            <div v-else>
              {{ scopedSlots.data }}
            </div>
          </template>
          <template v-slot:occurrenceDate="scopedSlots">
            <span>{{
              scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
            }}</span>
          </template>
          <template v-slot:occurrenceTime="scopedSlots">
            <span>{{
              displayUtcTime(get(scopedSlots.rowData, "occurrenceDate"))
            }}</span>
          </template>
          <template v-slot:dateCreated="scopedSlots">
            <span>{{
              scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
            }}</span>
          </template>
          <template v-slot:timeCreated="scopedSlots">
            <span>{{
              displayUtcTime(get(scopedSlots.rowData, "dateCreated"))
            }}</span>
          </template>
          <template v-slot:expiryDate="scopedSlots">
            <span>{{
              scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
            }}</span>
          </template>
          <template v-slot:isItemOpened="scopedSlots">
            <span>{{ scopedSlots.data ? "Open" : "Closed" }}</span>
          </template>
          <template v-slot:remarks="scopedSlots">
            <span>{{ scopedSlots.data ? scopedSlots.data : "-" }}</span>
          </template>
        </asom-client-table>
        <div v-else class="text-center">
          <asom-icon icon="spinner" class="animate-spin" />
        </div>
      </asom-card>
      <div class="pt-6">
        <asom-card title="Activity Log">
          <asom-client-table
            v-if="!isLoading"
            :columns="[
              'stationOccurrenceTransactionNo',
              'stationOccurrenceNo',
              'transactionType',
              'dateCreated',
              'timeCreated',
              'createdBy',
              'remarks',
            ]"
            :sortableColumns="[
              'stationOccurrenceTransactionNo',
              'stationOccurrenceNo',
              'transactionType',
              'dateCreated',
              'timeCreated',
              'createdBy',
              'remarks',
            ]"
            :data="activityLog"
          :searchableDateColumns="['dateCreated']"
          :searchableTimeColumns="['dateCreated']"
          >
            <template v-slot:header_createdBy>Last Modified By</template>
            <template v-slot:timeCreated="scopedSlots">
              <span>{{
                displayUtcTime(get(scopedSlots.rowData, "dateCreated"))
              }}</span>
            </template>
            <template v-slot:dateCreated="scopedSlots">
              <span>{{
                scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
              }}</span>
            </template>
            <template v-slot:stationOccurrenceTransactionNo="scopedSlots">
              <button
                @click="navigateToTransactionView(scopedSlots.rowData)"
                class="text-button underline font-semibold"
              >
                {{ scopedSlots.data }}
              </button>
            </template>
            <template v-slot:header_stationOccurrenceTransactionNo
              >Transaction No</template
            >
            <template v-slot:remarks="scopedSlots">
              <span v-if="scopedSlots.data"
                ><remarks-tooltip :text="scopedSlots.data" /></span
              ><span v-else>{{ "NA" }}</span>
            </template>
          </asom-client-table>
          <div v-else class="text-center">
            <asom-icon icon="spinner" class="animate-spin" />
          </div>
        </asom-card>
      </div>
    </div>
  </div>
</template>

<script>
import get from "lodash.get";
import moment from "moment";
import { mapGetters } from "vuex";
import {
  displayUtcDate,
  parseDateTime,
  displayUtcTime,
} from "@/helpers/dateTimeHelpers";
import { getListOfFaultOccurrence } from "../../../services/faultManagement.service";
import RemarksTooltip from "../../_RemarksTooltip.vue";
import { setStorage, getStorage } from "@/helpers/sessionStorage";

export default {
  components: {
    RemarksTooltip,
  },
  data() {
    return {
      filters: {
        line: this.$store.getters["auth/userLineOption"],
        station: null,
        dateRange: {
          start: moment().subtract(1, "month").toDate(),
          end: moment().add(1, "months").toDate(),
        },
        isItemOpened: ["open", "closed"],
      },
      isLoading: false,
      isSubmitting: false,
      faultOccurrenceList: [],
      activityLog: [],
      error: null,
      selectedItem: null,
      
      searchVal: null,
      filterEcho: 0,
    };
  },
  mounted() {
    this.searchVal = getStorage("station-occurrence");
    if(this.searchVal) {
      this.filterEcho = 1;

      // UTC时间转换成对应的本地时间
      if(this.searchVal.dateRange && this.searchVal.dateRange.start)  this.searchVal.dateRange.start = moment.utc(this.searchVal.dateRange.start).toDate();
      if(this.searchVal.dateRange && this.searchVal.dateRange.end) this.searchVal.dateRange.end = moment.utc(this.searchVal.dateRange.end).toDate();
      this.filters = this.searchVal;
    }
    this.$nextTick(() => {
      this.filters.station = this.$store.getters["smrtResource/stationOptionsByLineId"](
        this.userLineId, this.userStationId
      )
      this.getPageData();
    })
  },
  computed: {
    ...mapGetters({
      userLineId: "auth/userLineId",
      userStationId: "auth/userStationId",
      lineOptions: "smrtResource/lineOptions",
      canSelectLine: "auth/canSelectLine",
      canCreateStationOccurrence: "auth/canCreateStationOccurrence",
      canViewStationOccurrence: "auth/canViewStationOccurrence",
    }),
    itemStatusOptions() {
      return [
        { value: "open", label: "Open" },
        { value: "closed", label: "Closed" },
      ];
    },
    actionStatusOptions() {
      return [
        { value: true, label: "Action Required" },
        { value: false, label: "No Action Required" },
      ];
    },
    stationOptions() {
      return this.$store.getters["smrtResource/stationOptionsByLineId"](
        get(this.filters.line, "value")
      );
    },
    tableData() {
      return this.faultOccurrenceList;
    },
    queryParams() {
      let params = {};
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["searchDateFrom"] = parseDateTime(this.filters.dateRange.start);
        params["searchDateTo"] = parseDateTime(this.filters.dateRange.end);
      }
      params["lineId"] = get(this.filters.line, "value");
      params["stationId"] = this.filters.station
        ? get(this.filters.station, "value")
        : null;
      if (this.filters.isItemOpened.length > 0) {
        if (this.filters.isItemOpened.includes("open"))
          params["isItemOpen"] = true;
        else params["isItemOpen"] = false;
        if (this.filters.isItemOpened.includes("closed"))
          params["isItemClosed"] = true;
        else params["isItemClosed"] = false;
      }
      return params;
    },
    columns() {
      const fields = [
        'occurrenceNo',
        'description',
        'occurrenceDate',
        'occurrenceTime',
        'dateCreated',
        'timeCreated',
        'expiryDate',
        'isItemOpened',
        'createdByName',
      ];
      const labels = [
        'Occurrence No.',
        'Description',
        'Occurrence Date',
        'Occurrence Time',
        'Date Created',
        'Time Created',
        'Expiry Date',
        'Item Status',
        'Created By',
     ];
      const data = [];
      this.faultOccurrenceList.forEach((row, i) => {
        data[i] = fields.reduce((accm, field) => {
          let d = row[field];
          if (field == "occurrenceDate") {
            d = d ? displayUtcDate(d) : "NA";
          }
          if (field == "occurrenceTime") {
            d = row["occurrenceDate"];
            d = d ? displayUtcTime(d) : "NA";
          }
          if (field == "dateCreated") {
            d = d ? displayUtcDate(d) : "NA";
          }
          if (field == "timeCreated") {
            d = row["dateCreated"];
            d = d ? displayUtcTime(d) : "NA";
          }
          if (field == "expiryDate") {
            d = d ? displayUtcDate(d) : "NA";
          }
          if (field == "isItemOpened") {
            d = d ? "Open" : "Closed";
          }
          accm[field] = d;
          return accm;
        }, {});
      });
      return {
        fields,
        labels,
        data,
      };
    },
  },
  watch: {
    "filters.line": function (newValue) {
      if(!this.filterEcho || this.filterEcho > 1){
        if (newValue) {
          this.filters.station = null;
        }
      }
    },
  },
  methods: {
    get,
    displayUtcDate,
    displayUtcTime,
    resetFilters() {
      this.filters.dateRange = null;
    },
    navigateToView(selectedItem) {
      if (get(selectedItem, "occurenceId", null) == null) {
        selectedItem.occurenceId = get(
          selectedItem,
          "stationOccurrenceId",
          null
        );
      }
      this.$router.push({
        name: "View Station Occurrence",
        params: selectedItem,
      });
    },
    navigateToTransactionView(selectedItem) {
      if (get(selectedItem, "transactionId", null) == null) {
        selectedItem.transactionId = get(
          selectedItem,
          "stationOccurrenceTransactionId",
          null
        );
      }
      this.$router.push({
        name: "View Station Occurence Transaction Details",
        params: selectedItem,
      });
    },
    async getPageData(status) {
      if(status) setStorage("station-occurrence", this.filters);
      this.isLoading = true;
      const resp = await getListOfFaultOccurrence({
        ...this.queryParams,
      });
      if (resp.success) {
        this.faultOccurrenceList = get(
          resp.payload,
          "stationFaultOccurrences",
          []
        );
        this.activityLog = get(resp.payload, "transactions", []);
        this.isLoading = false;
        this.error = null;
      } else {
        this.isLoading = false;
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
  },
};
</script>
